import React from 'react';
import styled from 'styled-components';
import CarouselBackgroundImg from '../../resources/images/beer/carousel-background.jpg';
import ProductList from '../core/ProductList';
import ProductCarousel from '../core/ProductCarousel';
import TitleLetterImg1 from '../../resources/images/beer/decoration-title-1.svg';
import TitleLetterImg2 from '../../resources/images/beer/decoration-title-2.svg';
import TitleLetterImg3 from '../../resources/images/beer/decoration-title-3.svg';
import TitleLetterImg4 from '../../resources/images/beer/decoration-title-4.svg';
import TitleLetterImg5 from '../../resources/images/beer/decoration-title-5.svg';
import TitleLetterImg6 from '../../resources/images/beer/decoration-title-6.svg';
import TitleLetterImg7 from '../../resources/images/beer/decoration-title-7.svg';
import { Zoom } from 'react-reveal';


const ProductBackground = styled.div`
    background-color: #020913;
    width: 100%;
    padding-bottom: 110px;
    @media(max-width: 768px) {
        padding-bottom: 35px;
    }

`;
const CarouselBackground = styled.div`
    width: 100vw;
    height: 916px;
    background: url(${CarouselBackgroundImg}) no-repeat;
    background-size: cover;
    margin-top: -150px;
`;
const CarouselContainer = styled.div`
    width: 100vw;
    position: relative;
`;
const DesktopOnlyCarousel = styled.div`
    display:block;
    @media(max-width: 991px) {
        display:  none;
    }
`;
const MobileOnlyCarousel = styled.div`
    display:block;
    @media(min-width: 992px) {
        display:  none;
    }
`;
const DecorationTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
`;
const TitleLetter = styled.img`
    @media (max-width: 360px) {
        height: 22px !important;
    };
    @media (max-width: 768px) {
        height: 26px;
    };

    @media (min-width: 768px) and (max-width: 1128px) {
        height: 48px;
    };
    @media(min-width: 1128px) {
        height: 79px;
    }
`;

const CarouselBlock = () => {
    return (<ProductBackground>
        <DecorationTitle>
            <Zoom cascade duration={1500}>
                <TitleLetter src={TitleLetterImg1} />
                <TitleLetter src={TitleLetterImg2} />
                <TitleLetter src={TitleLetterImg3} />
                <TitleLetter src={TitleLetterImg4} />
                <TitleLetter src={TitleLetterImg4} />
                <TitleLetter src={TitleLetterImg2} />
                <TitleLetter src={TitleLetterImg5} />
                <TitleLetter src={TitleLetterImg3} />
                <TitleLetter src={TitleLetterImg6} />
                <TitleLetter src={TitleLetterImg1} />
                <TitleLetter src={TitleLetterImg2} />
                <TitleLetter src={TitleLetterImg7} />
            </Zoom>
        </DecorationTitle>
        <CarouselContainer>
            <DesktopOnlyCarousel>
                <ProductList />
            </DesktopOnlyCarousel>
            <MobileOnlyCarousel>
                <ProductCarousel />
            </MobileOnlyCarousel>
            <CarouselBackground />
        </CarouselContainer>
    </ProductBackground>);
}

export default CarouselBlock;