import React, { Component } from 'react';
import styled from 'styled-components';
import Product from './Product';
import ProductImg1 from '../../resources/images/products/product-img-1.png';
import ProductImg2 from '../../resources/images/products/product-img-2.png';
import ProductImg3 from '../../resources/images/products/product-img-3.png';
import { Container } from 'styled-bootstrap-grid';
import RightArrow from '../../resources/images/ui/right-arrow.svg';
import LeftArrow from '../../resources/images/ui/left-arrow.svg';


const ProductContainer = styled(Container)`
    padding-top: 200px;
    padding-bottom: 50px;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: 35px;
    z-index: 10;
    @media(max-width: 768px) {
        padding: 0px;
    }
`;
const CarouselArrows = styled.div`
    padding-top: 50px;
    text-align: center;
`;
const LeftArrowStyled = styled.img`
    width: 40px;
    padding-right: 15px;
`;
const RightArrowStyled = styled.img`
    width: 40px;
    padding-left: 15px;
`;
const ArrowSlideLeft = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;
const ArrowSlideRight = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;
class ProductCarousel extends Component {
    state = {
        productDisplayed: 0
    }

    slideProduct = (props) => {
        if (props === 1 && this.state.productDisplayed >= 0 && this.state.productDisplayed < 2) {
            this.setState({
                productDisplayed: this.state.productDisplayed + 1
            })
        } else if (props === -1 && this.state.productDisplayed > 0 && this.state.productDisplayed <= 2) {
            this.setState({
                productDisplayed: this.state.productDisplayed - 1
            })
        } else if (props === -1 && this.state.productDisplayed === 0) {
            this.setState({
                productDisplayed: 2
            })
        } else {
            this.setState({
                productDisplayed: 0
            })
        }
    }
    renderedProductContent = () => {
        if (this.state.productDisplayed === 0) {
            return <Product img={ProductImg1} name={"Lager"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />;
        } else if (this.state.productDisplayed === 1) {
            return <Product img={ProductImg2} name={"Pale ale"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />
        } else if (this.state.productDisplayed === 2) {
            return <Product img={ProductImg3} name={"Ipa"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />
        }
    }

    render() {
        const { slideProduct, renderedProductContent } = this;
        return (<ProductContainer>
            <div>{renderedProductContent()}</div>
            <CarouselArrows>
                <ArrowSlideLeft onClick={() => slideProduct(-1)}><LeftArrowStyled src={LeftArrow} /></ArrowSlideLeft>
                <ArrowSlideRight onClick={() => slideProduct(1)}><RightArrowStyled src={RightArrow} /></ArrowSlideRight>
            </CarouselArrows>
        </ProductContainer>);
    }
}

export default ProductCarousel;
