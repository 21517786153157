import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts'


const StyledTitle = styled.div`
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 35px;
    z-index: 1;
`;
const WhiteLine = styled.h1`
    text-transform: uppercase;
    color: white;
    letter-spacing: 6px;
    margin-bottom: 0;
    ${fonts.swissBlackExtended};
    font-size: 45px;
    @media (min-width: 586px) {
        font-size: 70px;
    }
    @media (min-width: 768px) {
        font-size: 77px;
    }  
`;

const Title = (props) => {
    const { title } = props;
    return (<StyledTitle>
        <WhiteLine>{title}</WhiteLine>
    </StyledTitle>);
}

export default Title;