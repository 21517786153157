import React from 'react';
import styled from 'styled-components';
import Title from '../core/WhiteTitle';
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from 'gatsby';
import OverlaySplash from './OverlaySplash';
import BlueLineImg from '../../resources/images/brewery/blue-line.svg';
import { Fade } from 'react-reveal';


const BackgroundStyled = styled.div`
    position: relative;
    height: 580px;
    margin-top: -138px;
    text-align: center;
    @media(max-width: 768px) {
        height: 420px;
    }
`;
const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  height: 100%;
  background-size: cover;
`;
const VerticalAlign = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const BlueLine = styled.img`
    padding-top: 25px;
`;

const HomeHero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "beer/beer-background.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.background.childImageSharp.fluid;

      return (
        <BackgroundStyled>
          <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={100}>
            <VerticalAlign>
              <Fade duration={500} delay={250}>
                <Title title={"IPA"} />
              </Fade>
              <Fade duration={500} delay={500}>
                <BlueLine src={BlueLineImg} />
              </Fade>
            </VerticalAlign>
            <OverlaySplash />
          </BackgroundImageStyled>
        </BackgroundStyled>
      )
    }
    }
  />);

export default HomeHero;
