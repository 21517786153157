import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import InfoBackgroundImg from '../../resources/images/beer/info-background.jpg';
import InfoBackgroundImgMobile from '../../resources/images/beer/info-background-mobile.jpg';
import DarkOverlayImg from '../../resources/images/beer/dark-overlay.png';
import { PoseGroup } from 'react-pose';


const InfoBackground = styled.div`
    background-color: #020913;
    width: 100%;
    padding-bottom: 110px;

`;
const ContainerStyled = styled(Container)`
    padding-top: 245px;
    padding-bottom: 125px;
    box-sizing: border-box;
    @media(max-width: 768px) {
        padding-top: 90px;
    }
`;
const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
    border-left: 1px solid #9DDCE2;
    box-sizing: border-box;
    border-right: ${props => props.last ? '1px solid #9DDCE2' : 'none'};
    height: 116px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 40px;
    padding-right: 40px;
    @media(max-width: 767px) {
        border: none;
        border-right: ${props => props.right ? '1px solid #9DDCE2' : 'none'};
        border-bottom: ${props => props.bottom ? '1px solid #9DDCE2' : 'none'};
        height: ${props => props.center ? '103px' : '79px'};
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 10px;
    }
    @media(max-width: 340px) {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const RowStyled = styled(Row)`
    margin-left: 0px;
    margin-right: 0px;
    border-top: 1px solid #9DDCE2;
    border-bottom: 1px solid #9DDCE2;
    position: relative;
    @media(max-width: 768px) {
        border: 1px solid #9DDCE2;
    }
`;
const ContentBackground = styled.div`
    width: 100vw;
    height: 600px;
    background: url(${InfoBackgroundImg}) no-repeat;
    background-size: cover;
    position: relative;
    @media(max-width: 768px) {
        background: url(${InfoBackgroundImgMobile}) no-repeat;
        background-size: cover;
        height: 480px;
    }
`;
const DarkOverlay = styled.img`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 154px;
    @media(max-width: 768px) {
        height: 126px;
    }
`;
const Title = styled.div`
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 23px;
`;
const Value = styled.div`
    color: white;
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    padding-top: 5px;
`;
const BorderStyled = styled.div`
    position: absolute;
    bottom: -7px;
    right: -7px;
    border-bottom: 7px solid #9DDCE2;
    border-right: 7px solid #9DDCE2;
    height: 85%;
    width: 97%;
    @media(max-width: 768px) {
        height: 94%;
        width: 96%;
    }
`;

class InfoBlock extends Component {
    state = {
        infoInView: false
    }
    changeVisibility = () => {
        this.setState({
            infoInView: true
        })
    }


    render() {
        return (<InfoBackground>
            <ContentBackground>
                <ContainerStyled>
                    <RowStyled >
                        <ColStyled xs={6} sm={6} md={2} bottom right>
                            <Title>Style</Title>
                            <Value>IPA</Value>
                        </ColStyled>
                        <ColStyled xs={6} sm={6} md={2} bottom>
                            <Title>Alc%</Title>
                            <Value>5.3</Value>
                        </ColStyled>
                        <ColStyled xs={6} sm={6} md={2} bottom right center>
                            <Title>Ibu</Title>
                            <Value>38</Value>
                        </ColStyled>
                        <ColStyled xs={6} sm={6} md={3} bottom center>
                            <Title>Malt</Title>
                            <Value>Pilsner, Wheat, Light Crystal, Munich</Value>
                        </ColStyled>
                        <ColStyled xs={12} sm={12} md={3} last>
                            <Title>Hops</Title>
                            <Value>Perle, Simcoe, Citra</Value>
                        </ColStyled>
                        <PoseGroup>
                            <BorderStyled key="border" />
                        </PoseGroup>
                    </RowStyled>
                </ContainerStyled>
                <DarkOverlay src={DarkOverlayImg} />
            </ContentBackground>
        </InfoBackground>);
    }
}

export default InfoBlock;