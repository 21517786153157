import React, {Component} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import ProductLine from '../../resources/images/products/product-line.png';
import {Fade} from 'react-reveal';
import posed from 'react-pose';
import { Link } from "gatsby"
import colors from "../../styles/colors"

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 768px) {
            display: ${props => props.dektoponly ? "none" : "block"}
        }
`;
const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;
const SingleProduct = styled.div`
`;

const ProductImg = styled.img`
    height: 384px;
    padding-bottom: 30px;
    padding-right: 85px;
    @media(min-width: 768px) {
        height: 403px;
    }
    @media (min-width: 992px) and (max-width: 1196px) {
        padding-right: 55px;
        margin-left: -30px;
    };
    @media(max-width: 346px) {
        padding-right: 65px;
        margin-left: -20px;
    }
`;
const ProductPercentage = styled.div`
    ${fonts.gilroyBold};
    color: ${colors.white};
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 15px;
    letter-spacing: 3px;
`;
const ProductTitle = styled.div`
        color: ${colors.white};
        ${fonts.swissBlackExtended};
        font-size: 19px;
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 15px;
`;
const ProductLineStyled = styled.img`
        padding-bottom: 15px;
`;
const ProductDescription = styled.div`
    ${fonts.gilroyRegular};
    font-size: 15px;
    color: ${colors.grey};
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 21px;
`;
const ProductButtonsContainer = styled.div`
    background: transparent;
    border-radius: 0;
    width: 285px;
    position: relative;
    margin: 0 auto;
`;
const ProductButton = styled.button`
    cursor: pointer;
    width: 50%;
    background: transparent;
    color: ${colors.white};
    border-radius: 0;
    ${fonts.gilroyBold};
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border-left: ${props => props.info ? "1px solid" + colors.mint : "none"};
    border-right: 1px solid ${colors.mint};
    border-bottom: 1px solid ${colors.mint};
    border-top: 1px solid ${colors.mint};
    letter-spacing: 3px;
    transition: background .1s, border .2s, color .1s;
    -webkit-transition: background .1s, border .1s, color .1s;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        background: ${colors.mint};
        border: 1px solid ${colors.mint};
        color: ${colors.navy};
    }
    @media(max-width: 768px) {
        padding-top: 14px;
        padding-bottom: 14px;
    }
     
`;
const BorderStyled = styled.div`
    position: absolute;
    top: 6px;
    right: -5px;
    background-color: transparent;
    border-bottom: 5px solid ${colors.mint};
    border-right: 5px solid ${colors.mint};
    height: 41px;
    width: 279px;
    z-index: -1;
    display: ${props => props.isVisible ? 'block' : 'none'};
    @media(max-width: 768px) {
        top: 4px;
    }
`;
const StyledLink = styled(Link)`
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;

// const HoverZoom = posed.div({
//   hoverable: true,
//   focusable: true,
//   pressable: true,
//   init: {
//     scale: 1
//   },
//   hover: {
//     scale: 1.2
//   },
//   press: {
//     scale: 1.1
//   },
//   focus: {
//     scale: 1.1
//   }
// });

let mass = 100 + getRandomInterval(0, 100);
let massTwo = 100 + getRandomInterval(0, 50);
let durationLong = 3000 + getRandomInterval(0, 500);
let durationShort = 1500 + getRandomInterval(0, 500);
let rotate = getRandomInterval(-1, 1);
let x = getRandomInterval(-5, 5);
let y = getRandomInterval(-10, 10);

const AnimatedProductImage = posed.div({
    start: {
      transform: 'rotate(0deg)',
      transition: {
        type: 'spring',
        mass: mass,
        duration: durationLong
      }
    },
    end: {
      transform: 'rotate(' + rotate + 'deg)',
      transition: {
        type: 'spring',
        mass: mass,
        duration: durationLong
      }
    }
  })
;

const AnimatedProductImageUpDown = posed.div({
    start: {
      x: 0,
      y: 0,
      transition: {
        type: 'spring',
        mass: massTwo,
        duration: durationShort
      }
    },
    end: {
      x: x + 'px',
      y: y + 'px',
      transition: {
        type: 'spring',
        // damping: 200,
        mass: massTwo,
        duration: durationShort
      }
    }
  })
;

function getRandomInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

class Product extends Component {
  state = {
    animate: 'start',
    isBorder: false,
  };

  componentDidMount() {
    let interval = durationLong + getRandomInterval(0, 1000);
    this.toggleHover()

    setInterval(() => {
      let animate = this.state.animate;

      if (animate === 'start') {
        animate = 'end';
      } else {
        animate = 'start';
      }

      this.setState({animate: animate});
    }, interval);
  }

  toggleHover = () => {
    this.setState({
      isBorder: !this.state.isBorder
    })
  };


  render() {
    const {img, name, description, link, abv} = this.props;
    const {animate} = this.state;

    return (<SingleProduct>
      <RowStyled>
        <ColStyled>
          <Fade duration={500}>
            <AnimatedProductImage pose={animate}>
              <AnimatedProductImageUpDown>
                <ProductImg src={img}/>
              </AnimatedProductImageUpDown>
            </AnimatedProductImage>
          </Fade>
        </ColStyled>
        <ColStyled><Fade duration={500}><ProductPercentage>5.3% abv</ProductPercentage></Fade></ColStyled>
        <ColStyled><Fade duration={500}><ProductTitle>{name}</ProductTitle></Fade></ColStyled>
        <ColStyled><Fade duration={500}><ProductLineStyled src={ProductLine}/></Fade></ColStyled>
        <ColStyled><Fade duration={500}><ProductDescription>{description}</ProductDescription></Fade></ColStyled>
        <ColStyled>
          <Fade bottom duration={500} delay={250}>
            <ProductButtonsContainer onMouseEnter={(this.toggleHover)} onMouseLeave={this.toggleHover}>
              <StyledLink to={"/beer"}>
                <ProductButton info>More info</ProductButton>
              </StyledLink>
              <StyledLink to={`/product/${link}`}>
                <ProductButton>Buy now</ProductButton>
              </StyledLink>
              <BorderStyled isVisible={this.state.isBorder}/>
            </ProductButtonsContainer>
          </Fade>
        </ColStyled>
      </RowStyled>
    </SingleProduct>);
  }
}

export default Product;
