import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import BeerImg from '../../resources/images/beer/beer-single.png';
import GreyLine from '../../resources/images/beer/broken-line.svg';
import { Fade } from 'react-reveal';


const WhiteBlockContainer = styled.div`
    width: 100%;
    background: white;
    padding-left: 0px;
    padding-right: 0px;
`;
const ContentContainer = styled(Container)`
    padding-top: 100px;
    padding-bottom: 100px;    
    box-sizing: border-box;
    @media(max-width: 768px) {
        padding-bottom: 50px;
        padding-top: 50px;
    }
`;
const RowStyled = styled(Row)`
    padding-bottom: 40px;
    padding-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
`;
const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    @media(max-width: 768px) {
        order: ${props => props.controlled0 ? '0' : ''};
        order: ${props => props.controlled1 ? '1' : ''};
    }
`;
const BeerSingleImg = styled.img`
    max-width: 423px;
    width: 90%;
    margin: auto;
    height: auto;
    position: relative;
    z-index: 1;
    @media(max-width: 767px) {
        margin-left: -10%;
    }
`;
const SmallTitle = styled.div`
    ${fonts.gilroyBold};
    font-size: 12px;
    letter-spacing: 2.77px;
    line-height: 12px;
    color: #222E3C;
    text-transform: uppercase;
    padding-top: 100px;
    padding-bottom: 15px;
    @media(max-width: 767px) {
        padding-top: 25px;
        text-align: center;
    }
`;
const BeerTitle = styled.div`
    ${fonts.swissBlackExtended};
    color: #222E3C;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 62px;
    line-height: 74px;
    @media(max-width: 960px) {
        font-size: 38px;
        line-height: 48px;
    }
    @media(max-width: 767px) {
       text-align: center;
       font-size: 30px;
       line-height: 40px;
       padding-bottom: 30px;
    }
`;
const BrokenLine = styled.img`
    max-width: 342px;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 35px;
    margin-left: -30%;
    position: relative;
    z-index: 0;
    @media(max-width: 767px) {
        display: none;
    }
`;
const Description = styled.div`
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    color: #222E3C;
`;
const PStyled = styled.p`
    margin-bottom: 10px;
    padding-right: 5%;
    max-width: 380px;
`;

const WhiteBlock = () => {
    return (
        <WhiteBlockContainer>
            <ContentContainer>
                <RowStyled>
                    <ColStyled md={6}>
                        <Fade duration={2000} delay={0}>
                            <BeerSingleImg src={BeerImg} />
                        </Fade>
                    </ColStyled>
                    <ColStyled md={6}>
                        <Fade duration={500} delay={0}>
                            <SmallTitle>Introducing</SmallTitle>
                        </Fade>
                        <Fade duration={500} delay={250}>
                            <BeerTitle>Voyager</BeerTitle>
                        </Fade>
                        <Fade right duration={1000} delay={500}>
                            <BrokenLine src={GreyLine} />
                        </Fade>
                        <Description>
                            <Fade duration={500} delay={750}>
                                <PStyled>Lose yourself in the depths of this rich gold IPA. Savour its balance of bittersweet hops and velvety malt. Relish its tang of passion fruit, pineapple, grapefruit and Jamaican rum.  </PStyled>
                            </Fade>
                            <Fade duration={500} delay={1000}>
                                <PStyled>Let its waves of flavour carry you away.  Rich gold in colour, this exceptional IPA balances bittersweet hops and velvety malt with the tang of passion fruit, pineapple and grapefruit. Lose yourself in its flood of full-on flavours.</PStyled>
                            </Fade>
                        </Description>
                    </ColStyled>
                </RowStyled>
            </ContentContainer>
        </WhiteBlockContainer>
    )
}

export default WhiteBlock;

