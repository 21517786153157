import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import ProductImg1 from '../../resources/images/products/product-img-1.png';
import ProductImg2 from '../../resources/images/products/product-img-2.png';
import ProductImg3 from '../../resources/images/products/product-img-3.png';
import SingleProduct from './Product';


const ProductContainer = styled(Container)`
    padding-top: 200px;
    padding-bottom: 50px;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: -35px;
    z-index: 10;
`;
const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 768px) {
            display: ${props => props.dektoponly ? "none" : "block"}
        }
`;
const RowStyled = styled(Row)`
    margin-left: 0px;
    margin-right: 0px;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;

const ProductList = () => {
    return (<ProductContainer>
        <RowStyled>
            <ColStyled md={4}>
                <SingleProduct img={ProductImg1} name={"Lager"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />
            </ColStyled>
            <ColStyled md={4}>
                <SingleProduct img={ProductImg2} name={"Pale ale"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />
            </ColStyled>
            <ColStyled md={4}>
                <SingleProduct img={ProductImg3} name={"Ipa"} description={"Heady mix of citrus, spearmint and hopsack aromas"} />
            </ColStyled>
        </RowStyled>
    </ProductContainer>)
}

export default ProductList;
