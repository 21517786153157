import React, { Component } from 'react';
import styled from 'styled-components';
import Hero from '../components/beersPDP/Hero';
import Layout from '../components/core/Layout';
import SEO from '../components/core/Seo';
import WhiteBlock from '../components/beersPDP/WhiteBlock';
import CarouselBlock from '../components/beersPDP/CarouselBlock';
import InfoBlock from '../components/beersPDP/InfoBlock';
import PrivateRoute from "../router/PrivateRoute"


const LayoutStyled = styled(Layout)`
  margin: 0px;
`;

class BeerPage extends Component {
  state = {}

  render() {
    const path = this.props.location.pathname;

    return (
      <LayoutStyled>
        <SEO title="Lost At Sea"
          description="A storm is brewing." fullTitle={true}
          path={path} />
        <Hero />
        <WhiteBlock />
        <InfoBlock />
        <CarouselBlock />
      </LayoutStyled>
    )
  }
}

function beersDetail(props) {
  return <PrivateRoute component={BeerPage} {...props}/>
}

export default beersDetail;
